.layout_main {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.layout_header {
  height: 60px;
  width: 100%;
}
.layout_body {
  height: auto;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.layout_footer {
  height: auto;
  width: 100%;
  display: flex;
}
