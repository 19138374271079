.header_main {
  height: 60px;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}
.menu_icon {
  display: none;
}
.header_logo {
  font-size: 24px;
  font-weight: 700;
  color: #09069b;
  cursor: pointer;
}

.header_headerbg {
  height: 100px;
  width: 120%;
  object-fit: fill;
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  display: flex;
  margin-left: -100px;
  right: 0;
}
.header_content {
  display: flex;
  z-index: 1;
  align-items: center;
  padding-inline: 3rem;
  gap: 2rem;
  width: 100%;
}
.header_link {
  font-size: 14px;
  font-weight: 600;
  color: #1c3e5c;
  cursor: pointer;
}

.header_login {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  cursor: pointer;
}
.drawer_link {
  font-size: 14px;
  font-weight: 600;
  color: #1c3e5c;
  cursor: pointer;
  margin-top: 1rem;
}

@media (max-width: 600px) {
  .header_link {
    display: none;
  }
  .btn_div {
    display: none;
  }
  .menu_icon {
    display: flex;
    cursor: pointer;
  }
  .header_headerbg {
    margin-left: -20px;
  }
  .header_content {
    padding-inline: 1rem;
  }
}
