.footer_main {
  background-color: #a5bffc;
  display: flex;
  flex: 1;
  padding: 1.7rem;
  flex-direction: column;
}

.footer_content_div {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  align-items: center;
  gap: 1rem;
}
.footer_heading_div {
  // flex: 1;
  align-self: flex-start;
}
.line {
  border-bottom: 1px solid #0d0c0c;
  padding-bottom: 1rem;
  max-width: 65%;
}
.footer_heading {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  line-height: 24px;
  max-width: 65%;
}
.footer_title {
  font-size: 16px;
  font-weight: 500;
  color: #5b5050;
  line-height: 24px;
}
.footer_row {
  display: flex;
  gap: 15px;
  align-items: center;
  // width: 40%;
}
.footer_box {
  background-color: #faf9f9;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  align-items: center;
  margin-top: 1rem;
}
.footer_logo {
  font-size: 36px;
  color: #09069b;
  font-weight: 700;
  letter-spacing: 7px;
}
.f_icons {
  color: #0d0c0c;
  display: flex;
  cursor: pointer;

  :hover {
    color: #398afe;
  }
}
@media (max-width: 600px) {
  .footer_main {
    padding: 0.5rem;
  }
  .footer_content_div {
    // margin-top: 0.5rem;
    gap: 0.5rem;
    flex-direction: column;
    margin-top: 10px;
  }
  .footer_row {
    align-items: start;
  }
  .footer_logo {
    font-size: 25px;
    letter-spacing: 3px;
  }
  .footer_box {
    padding: 0.5rem;
    margin-top: 0.5rem;
  }
  .footer_heading {
    line-height: 16px;
    font-size: 12px;
    max-width: 100%;
  }
  .line {
    max-width: 100%;
  }
}

/* Styles for screens larger than 768px */
@media (max-width: 768px) {
  .footer_main {
    padding: 1rem;
  }
  .footer_content_div {
    margin-top: 1rem;
    gap: 0.5rem;
    flex-direction: column;
  }
  .footer_row {
    align-items: flex-start;
  }
  .footer_heading {
    max-width: 100%;
  }
  .line {
    max-width: 100%;
  }
}
