.button_main {
  background-color: #4771db;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  align-self: center;
  display: flex;
}
