.w_main {
  width: 100%;
}
.w_img_div {
  height: 600px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
}
.w_img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}
.h_content_title_main {
  font-size: 40px;
  font-weight: 600;
  color: #5b5050;
  margin-top: 20px;
  padding-inline: 20px;
}
.h_content_title_main1 {
  font-size: 20px;
  font-weight: 400;
  color: #5b5050;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-inline: 20px;
}
.h_content_title {
  font-size: 25px;
  font-weight: 600;
  color: #5b5050;
  margin-bottom: 10px;
  margin-top: 10px;
}

.h_content_text1 {
  font-size: 16px;
  font-weight: 400;
  color: #5b5050;
  line-height: 25px;
}
.h_content_text1_span {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.h_content_row {
  display: flex;
  gap: 1rem;
  padding-inline: 2rem;
  margin-block: 1rem;
}

.h_content_row_text1 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.h_content_row_img_div1 {
  height: 400px;
  flex: 0.6;
  max-width: 600px;
}
.h_content_row_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
}

@media (max-width: 600px) {
  .w_img_div {
    height: 300px;
  }
  .h_content_row {
    display: flex;
    gap: 0.5rem;
    padding-inline: 1rem;
    margin-block: 1rem;
    flex-direction: column;
  }
  .h_content_title {
    font-size: 20px;
    font-weight: 600;
    color: #5b5050;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .h_content_text1 {
    font-size: 12px;
    font-weight: 400;
    color: #5b5050;
    line-height: 15px;
  }
  .h_content_row_img_div1 {
    height: 300px;
    flex: 1;
    max-width: 600px;
  }
  .h_content_row_img {
    width: 100%;
    height: 200px;
    object-fit: fill;
    border-radius: 10px;
  }
}

@media (max-width: 400px) {
  .w_img_div {
    height: 200px;
  }
  .h_content_row {
    display: flex;
    gap: 0.5rem;
    padding-inline: 10px;
    margin-block: 10px;
    flex-direction: column;
  }
  .h_content_row_img {
    width: 100%;
    height: 150px;
    object-fit: fill;
    border-radius: 10px;
  }
}
