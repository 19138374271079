.h_main {
  width: 100%;
}
.h_img_div {
  // height: 30%;
  height: 600px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
}
.h_img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}
.h_img_content {
  position: absolute;
  padding-inline: 2rem;
}

.h_img_content_logo {
  font-family: "Poppins", sans-serif;
  font-size: 48px;
  font-weight: 600;
  color: #fff;
}
.h_img_content_heading {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 24px;
}
.h_content_title {
  font-size: 32px;
  font-weight: 600;
  color: #5b5050;
  line-height: 32px;
  margin-bottom: 10px;
}
.h_content_text {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.h_content_text1 {
  font-size: 16px;
  font-weight: 400;
  color: #5b5050;
  line-height: 31px;
}
.h_content_row {
  display: flex;
  gap: 2rem;
  padding-inline: 2rem;
  margin-top: 1rem;
}
.h_content_row_text {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 5rem;
}
.h_content_row_text1 {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}
.h_content_row_img_div {
  height: 500px;
  flex: 0.8;
  max-width: 600px;
  margin-top: -150px;
  z-index: 1;
}
.h_content_row_img_div1 {
  height: 400px;
  flex: 0.8;
  max-width: 600px;
}
.h_content_row_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 10px;
  overflow: hidden;
}
.space {
  width: 100%;
  height: 2rem;
  display: flex;
}
@media (max-width: 600px) {
  .h_content_row_img_div {
    height: 100px !important;
    flex: 1;
    max-width: 100%;
    margin-top: 0px;
    z-index: 1;
  }
  .h_content_row_img_div1 {
    height: 220px;
    flex: 0;
    max-width: 100%;
  }
  .h_content_row_img {
    width: 100%;
    height: 220px;
    object-fit: fill;
    border-radius: 10px;
  }
  .h_content_row {
    display: flex;
    gap: 1rem;
    padding-inline: 1rem;
    margin-top: 1rem;
    flex-direction: column;
  }
  .h_img_div {
    height: 300px;
  }
  .h_img_content_logo {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }
  .h_img_content_heading {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    line-height: 24px;
  }
  .h_img_content {
    position: absolute;
    padding-inline: 1rem;
  }
  .h_content_text {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .h_content_text1 {
    font-size: 14px;
    line-height: 18px;
  }
  .h_content_title {
    font-size: 20px;
    line-height: 20px;
    // margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  .h_img_div {
    height: 200px;
  }
  // .h_content_row_img_div {
  //   height: 100px !important;
  //   flex: 1;
  //   max-width: 100%;
  //   margin-top: 0px;
  //   z-index: 1;
  // }
  // .h_content_row_img_div1 {
  //   height: 150px;
  //   flex: 0;
  //   max-width: 100%;
  // }
  .h_content_row_img {
    width: 100%;
    height: 150px;
    object-fit: fill;
    border-radius: 10px;
  }
}
@media (max-width: 1000px) {
  .h_content_row_text {
    margin-right: 0rem;
  }
}
