.c_main {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.c_img_div {
  height: 600px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
}
.c_img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}
.c_form_div {
  align-self: center;
  max-width: 70%;
  width: 100%;
  padding: 2rem;
}
.c_heading {
  font-size: 32px;
  font-weight: 600;
  color: #000000;
}
.c_row {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.input_box {
  flex: 1;
  border-radius: 5px;
  border: 1px solid #000000;
  padding: 10px;
  font-weight: 400;
  color: #000000;
  outline: none;
}
.input_area {
  flex: 1;
  border-radius: 5px;
  border: 1px solid #000000;
  padding: 10px;
  display: flex;
  min-height: 100px;
  font-family: "Montserrat", sans-serif;
  outline: none;
  font-weight: 400;
  color: #000000;
  resize: vertical;
}
.map {
  border: 0;
  margin-block: 1.5rem;
  height: 550px;
}
@media (max-width: 400px) {
  .c_img_div {
    height: 200px;
  }
}
@media (max-width: 600px) {
  .input_area {
    min-height: 70px;
  }
  .c_img_div {
    height: 300px;
  }
  .c_form_div {
    max-width: 95%;
    padding: 0.5rem;
  }
  .c_row {
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
  }
  .map {
    border: 0;
    margin-block: 0.5rem;
    height: 300px;
  }
  .c_heading {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
  }
}
@media (max-width: 400px) {
  .c_img_div {
    height: 200px;
  }
  .c_form_div {
    max-width: 90%;
    padding: 0.5rem;
  }
  .c_row {
    flex-direction: column;
  }
  .map {
    border: 0;
    margin-block: 0.5rem;
    height: 200px;
  }
}
